import { Outlet, Params, useParams, useSearchParams } from "react-router-dom";
//import CompaniesListSearch from "./components/companies-list-search.tsx";
import { useInfiniteQuery } from "@tanstack/react-query";
import HeaderDropdownMenu from "./components/header/DropdownMenu";
import { useState, useMemo } from "react";
import CompanyListItem from "@/routes/companies/companies-list/components/companyListItem.tsx";
import { InView } from "react-intersection-observer";
import CompanyListItemSkeleton from "@/routes/companies/companies-list/components/companyListItemSkeleton.tsx";
import { useAsync } from "react-async-hook";
import { useSuspenseQuery } from "@tanstack/react-query";
import { pick } from "lodash";
import { companiesFilterQueryOptions } from "../companies-filter/companies-filter.loader.ts";
import dataAnalysis from "../../../assets/images/data_analysis.svg";
import TrackingCodeDrawer from "@/routes/account/TrackingCodeDrawer";

export interface CompanyListData {
  city: string;
  company_id: string;
  company_logo_url: string;
  company_name: string;
  country: string;
  last_session_timestamp: string;
  lead_score: number;
  industry?: string;
}

async function getCompaniesList(
  searchParams: URLSearchParams,
  params: Readonly<Params<string>>,
  pageParam: number,
) {
  const filter = new URLSearchParams(searchParams);
  filter.set("page", String(pageParam));
  filter.set("page_size", String(10));

  if (params.domain) filter.set("domain", params.domain);
  if (params.company_details_id)
    filter.set("company_details_id", params.company_details_id);

  const res = await fetch(
    `${import.meta.env.VITE_APP_API}/api/companies_list?${filter}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
  return await res.json();
}

function CompaniesListView() {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { data: filterList } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );
  const [trackingCodeOpen, setTrackingCodeOpen] = useState(false);
  const openTrackingCodeDrawer = () => {
    setTrackingCodeOpen(true);
  };
  const {
    data: companiesList,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["api", "companies_list", searchParams, pick(params, "domain")],
    queryFn: ({ pageParam }) =>
      getCompaniesList(searchParams, params, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { isLast, current } = lastPage.pagination[0];
      return isLast ? undefined : current + 1;
    },
    getPreviousPageParam: (firstPage) => {
      const { current, isFirst } = firstPage.pagination[0];
      return isFirst ? undefined : current - 1;
    },
  });

  useAsync(async () => {
    await refetch();
  }, [searchParams]);

  const flatList =
    useMemo(
      () => companiesList?.pages.flatMap((p) => p.result),
      [companiesList],
    ) || [];
  // useEffect(() => {
  //   const firstCompanyId = companiesList?.pages[0]?.result[0]?.company_id;
  //
  //   if (firstCompanyId && !params?.company_details_id) {
  //     navigate({ pathname: `${firstCompanyId}`, search: location.search });
  //   }
  // }, [companiesList, navigate, params?.company_details_id]);
  // Check if "all time" has companies
  const allTimeOption = filterList?.find((item) => item.label === "all_time");
  const hasAllTimeCompanies = allTimeOption && allTimeOption.companies > 0;
  return (
    <div className="xl:pl-72">
      {" "}
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-background px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-bold">
          {hasAllTimeCompanies
            ? "Identifizierte Unternehmen"
            : "Willkommen bei Webmetic"}
        </h1>
        <div className="w-full flex-1"></div>
        <HeaderDropdownMenu />
      </div>
      {/* <header className="flex items-center justify-between border-b bg-background p-2 xl:sticky xl:top-0 xl:z-10">
          <CompaniesListSearch />
        </header> */}
      {hasAllTimeCompanies ? (
        <>
          <aside className="lg:fixed lg:bottom-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-r xl:left-72">
            <div // _-_57px after 100% for search bar
              className="flex min-h-[calc(100%)] grow flex-col gap-3 bg-[#f0f2f5] pl-4 pr-3 pt-3"
              id="company-list"
            >
              {flatList.map((company: CompanyListData) => (
                <CompanyListItem key={company.company_id} company={company} />
              ))}
              <InView
                onChange={(inView) => {
                  if (inView && hasNextPage) fetchNextPage();
                }}
                disabled={!hasNextPage}
              >
                {hasNextPage && <CompanyListItemSkeleton />}
              </InView>
            </div>
          </aside>
          <main className="lg:pl-96">
            <Outlet />
          </main>
        </>
      ) : (
        <div className="flex h-[calc(100vh-4rem)] items-center justify-center">
          <div className="container mx-auto px-4 lg:px-6">
            <div className="flex flex-col items-center lg:flex-row">
              {/* Text content (1/3) */}
              <div className="ml-8 flex w-full flex-col items-center justify-center text-center lg:ml-16 lg:w-1/3">
                <svg
                  className="mb-4 h-12 w-12 text-green-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <h1 className="mb-2 text-2xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
                  Konto erfolgreich eingerichtet
                </h1>
                <p className="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">
                  Die ersten Unternehmensinformationen werden verfügbar, sobald
                  du den Tracking Code eingebunden hast.
                </p>
                <button onClick={openTrackingCodeDrawer} className="btn">
                  Tracking Code anzeigen
                </button>
              </div>
              {/* Image (2/3) */}
              <div className="w-full lg:w-2/3">
                <img
                  className="w-full rounded-lg"
                  src={dataAnalysis}
                  alt="Leads identifizieren"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <TrackingCodeDrawer
        open={trackingCodeOpen}
        onClose={() => setTrackingCodeOpen(false)}
      />
    </div>
  );
}

export default CompaniesListView;
