import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  CompaniesFilterDate,
  companiesFilterQueryOptions,
} from "./companies-filter.loader.ts";
import clsx from "clsx";
import DateFilterDialog from "./components/date-filter-dialog";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  format,
  isSameDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  parseISO,
  isValid,
} from "date-fns";
import LogoLight from "@/assets/images/webmetic_logo_light.png";
import LogoDark from "@/assets/images/webmetic_logo_dark.png";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import useUser from "@/hooks/useUser.ts";

const germanLabels: { [key: string]: string } = {
  today: "Heute",
  yesterday: "Gestern",
  this_week: "Diese Woche",
  last_week: "Letzte Woche",
  this_month: "Dieser Monat",
  this_year: "Dieses Jahr",
  all_time: "Alle",
  january: "Januar",
  february: "Februar",
  march: "März",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Dezember",
  2023: "2023",
  2024: "2024",
};

const toValidRangeDate = (dateString: string | null): Date | undefined => {
  if (!dateString) return undefined;
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : undefined;
};

function CompaniesFilterView() {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const { data: list } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: toValidRangeDate(searchParams.get("from_date")) || undefined,
    to: toValidRangeDate(searchParams.get("to_date")) || undefined,
  });

  useEffect(() => {
    document.documentElement.setAttribute("data-route", "dashboard");
    return () => document.documentElement.removeAttribute("data-route");
  }, []);

  const resetSelectedCompany = (domain = params.domain) => {
    const n = new URLSearchParams(searchParams);
    n.delete("company_id");
    navigate({
      pathname: `/dashboard/${domain}/companies`,
      search: n.toString(),
    });
  };

  const getDateRange = (item: CompaniesFilterDate): DateRange => {
    const today = new Date();
    switch (item.label) {
      case "today":
        return { from: today, to: today };
      case "yesterday":
        const yesterday = subDays(today, 1);
        return { from: yesterday, to: yesterday };
      case "this_week":
        return { from: startOfWeek(today), to: endOfWeek(today) };
      case "last_week":
        const lastWeekStart = startOfWeek(subDays(today, 7));
        return { from: lastWeekStart, to: endOfWeek(lastWeekStart) };
      case "this_month":
        return { from: startOfMonth(today), to: endOfMonth(today) };
      case "this_year":
        return { from: startOfYear(today), to: endOfYear(today) };
      case "all_time":
        // You might want to set a specific start date for 'all_time'
        return { from: new Date(2000, 0, 1), to: today };
      default:
        // For specific months or years
        if (
          item.label.match(
            /^(january|february|march|april|may|june|july|august|september|october|november|december)$/,
          )
        ) {
          const year = today.getFullYear();
          const month = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
          ].indexOf(item.label);
          const start = new Date(year, month, 1);
          return { from: start, to: endOfMonth(start) };
        }
        if (item.label.match(/^\d{4}$/)) {
          const year = parseInt(item.label, 10);
          return { from: new Date(year, 0, 1), to: new Date(year, 11, 31) };
        }
        // Default case: use the provided dates
        return {
          from: toValidRangeDate(item.date_start) || undefined,
          to: toValidRangeDate(item.date_end) || undefined,
        };
    }
  };

  const handleSelectDateRangeOption = (item: CompaniesFilterDate) => () => {
    const range = getDateRange(item);
    updateSearchParamsDateRange(range);
  };

  const updateSearchParamsDateRange = (dr?: DateRange) => {
    resetSelectedCompany();
    setSearchParams((p) => {
      const n = new URLSearchParams(p);
      if (dr?.from) n.set("from_date", format(dr.from, "yyyy-MM-dd"));
      else n.delete("from_date");
      if (dr?.to) n.set("to_date", format(dr.to, "yyyy-MM-dd"));
      else n.delete("to_date");
      return n;
    });
  };

  useEffect(() => {
    setDateRange({
      from: toValidRangeDate(searchParams.get("from_date")) || undefined,
      to: toValidRangeDate(searchParams.get("to_date")) || undefined,
    });
  }, [searchParams]);

  useEffect(() => {
    setDateRange({
      from: toValidRangeDate(searchParams.get("from_date")) || undefined,
      to: toValidRangeDate(searchParams.get("to_date")) || undefined,
    });
  }, [searchParams]);

  const handleUpdateSearchParams = () => updateSearchParamsDateRange(dateRange);

  const selectedItem = useMemo(() => {
    const f = toValidRangeDate(searchParams.get("from_date"));
    const t = toValidRangeDate(searchParams.get("to_date"));
    if (!f || !t) return null;
    for (const item of list) {
      const dateStart = parseISO(item.date_start);
      const dateEnd = parseISO(item.date_end);
      if (isSameDay(dateStart, f) && isSameDay(dateEnd, t)) {
        return item;
      }
    }
    return null;
  }, [searchParams, list]);

  return (
    <>
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
        <div className="flex grow flex-col overflow-y-auto border-r bg-muted/40">
          <div className="sticky top-0 flex h-16 shrink-0 items-center border-b bg-background px-6">
            <img
              src={LogoLight}
              className="mr-3 mt-1 h-7 dark:hidden sm:h-9"
              alt="Webmetic Logo"
            />
            <img
              src={LogoDark}
              className="mr-3 mt-1 hidden h-7 dark:block sm:h-9"
              alt="Webmetic Logo"
            />
          </div>
          {!!user?.website.length && (
            <div className="sticky top-16 border-b bg-background p-2">
              <Select
                value={params.domain}
                onValueChange={resetSelectedCompany}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Domain" />
                </SelectTrigger>
                <SelectContent>
                  {user.website.map((v) => (
                    <SelectItem key={v} value={v}>
                      {v}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          <nav className="flex flex-1 flex-col px-6">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {list.map((item) => (
                    <li key={item.label}>
                      <button
                        type="button"
                        onClick={handleSelectDateRangeOption(item)}
                        className={clsx(
                          selectedItem?.label === item.label
                            ? "bg-muted text-primary hover:text-primary"
                            : "text-muted-foreground hover:text-primary",
                          "group flex w-full justify-between gap-x-3 rounded-md px-2 py-1 text-sm font-semibold leading-6 transition-all",
                        )}
                      >
                        <span className="truncate capitalize">
                          {germanLabels[item.label] || item.label}
                        </span>
                        <span className="inline-flex min-h-6 min-w-6 shrink-0 items-center justify-center rounded-lg border px-2 py-1 text-xs font-medium text-muted-foreground group-hover:text-primary">
                          {item.companies}
                        </span>
                      </button>
                    </li>
                  ))}
                  <li>
                    <DateFilterDialog
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      onSubmit={handleUpdateSearchParams}
                      trigger={
                        <button
                          type="button"
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border p-2 text-sm font-semibold leading-6",
                          )}
                        >
                          <span className="capitalize">Zeitraum festlegen</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Datum manuell auswählen
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>
              </li>
              <li>
                {/*
                <div className="flex items-center justify-between py-4">
                  <span className="text-sm font-semibold leading-6 text-primary">
                    Segmente
                  </span>

                </div>
                <ul role="list" className="-mx-2 my-2 space-y-1">
                  <li>
                    <SegmentsDrawer
                      trigger={
                        <button
                          type="button"
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border p-2 text-sm font-semibold leading-6",
                          )}
                        >
                          <span className="capitalize">New Segment</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Create your advanced companies filter
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>  */}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CompaniesFilterView;
